<template>
  <div class="logout">
    <span>
      <Spin size="large" />
    </span>
  </div>
</template>

<script>
import { logout } from "@/api"
import { mapActions } from "vuex"

export default {
  name: "Logout",
  async mounted() {
    try {
      await logout()
    } catch (e) {
      console.log("logout exception: ", e.message)
    }
    sessionStorage.removeItem("token")
    this.clearStorage()
    this.$router.replace({ name: "Login" })
  },
  methods: {
    ...mapActions(["clearStorage"]),
  },
}
</script>

<style>
.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
